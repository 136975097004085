'use client';
import { widthBpsInEm } from '@haaretz/l-theme-shared/lib/consts';
import HtzImage from '@haaretz/s-htz-image';
import getImgTitle from '@haaretz/s-image-utils/getImgTitle';
import * as React from 'react';
import { preload } from 'react-dom';
import s9 from 'style9';

import HtzSource from './HtzSource';
import { buildPriorityLoading, generateMediaQueryForPreload } from './utlis';

import type { WidthBpName } from '@haaretz/l-theme-shared/lib/consts';
import type { ImageFragment } from '@haaretz/s-fragments/HTZ_image_Image';
import type { ImageAspect } from '@haaretz/s-fragments/Types';
import type { BaseSizeProps as DefaultSize } from '@haaretz/s-image-utils/buildSizesString';
import type { StyleExtend, InlineStyles, Tuple2Union } from '@haaretz/s-types';
// `c` is short for `classNames`
const c = s9.create({
  base: {
    height: 'auto',
    maxWidth: '100%',
    verticalAlign: 'middle',
    width: '100%',
  },
});

export interface Size extends DefaultSize {
  from: WidthBpName;
}

type OnLoad = (img: HTMLImageElement) => void;
type VALID_LOADING_VALUES = ['lazy', 'eager', undefined];

type LoadingValue = Tuple2Union<VALID_LOADING_VALUES>;

export type Source = {
  aspect: ImageAspect;
  from: WidthBpName;
  type?: 'avif' | 'webp';
  sizes: [...Array<Size>, DefaultSize];
  widths: Array<number>;
  priority?: boolean;
};

export type FallbackSource = Omit<Source, 'type' | 'from'> & { widths: Array<number> };
export type Sources = [FallbackSource, ...Array<Source>];

type ServerData = Pick<ImageFragment, 'credit' | 'contentId' | 'caption' | 'alt'>;
type ImgElementAttrs = Omit<
  React.ComponentPropsWithoutRef<'img'>,
  'src' | 'srcSet' | 'sizes' | 'alt' | 'className' | 'style' | 'onLoad' | 'onError'
>;

export interface HtzPictureProps extends ServerData, ImgElementAttrs {
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyHtzPicture(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <HtzPicture
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <HtzPicture styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
  imgData: ImageFragment['files'][number];
  sources: Sources;
  contentId: string;
  loading?: LoadingValue;
  onLoad?: OnLoad;
  isLazy?: boolean;
  priority?: boolean;
  onError?: React.ReactEventHandler<HTMLImageElement>;
  envOverride?: TEnv;
}

const preloadOpts = {
  as: 'image',
  fetchPriority: 'high',
} as const;

export default function HtzPicture({
  inlineStyle,
  styleExtend = [],
  sources,
  imgData,
  alt,
  contentId,
  onLoad,
  isLazy,
  onError,
  loading,
  priority,
  credit,
  caption,
  envOverride,
  ...attrs
}: HtzPictureProps) {
  if (!sources || sources.length === 0) {
    throw new Error(
      '[HtzPicture]: the "sources" prop must be an array with at least two items: an item for <source /> and an item for <img /> \nIf you only need one aspect ratio or image type, use HtzImage instead.'
    );
  }
  if (sources.length === 1) {
    throw new Error(
      '[HtzPicture]: the `sources` prop must be an array with at least two items: an item for <source /> and an item for <img />\nnIf you only need one aspect ratio or image type, use HtzImage instead.'
    );
  }

  const [fallbackImg, ...restSources] = sources;

  for (const source of restSources) {
    if (!source.aspect) {
      throw new Error(
        '[HtzPicture]: the `source` elements in the `sources` array must have aspect ratio prop'
      );
    }

    if (!source.sizes) {
      throw new Error(
        '[HtzPicture]: the `source` elements in the `sources` array must have sizes prop'
      );
    }

    if (!source.widths) {
      throw new Error(
        '[HtzPicture]: the `source` elements in the `sources` array must have widths prop'
      );
    }

    if (!source.from) {
      throw new Error(
        '[HtzPicture]: the `source` elements in the `sources` array must provide breakpoint for media query via `from` prop'
      );
    }
  }

  const sortedSources = restSources.sort((a, b) => {
    if (!b.from) {
      return 1;
    }

    if (a.from) {
      return widthBpsInEm[b.from] - widthBpsInEm[a.from];
    }

    return 0;
  });

  const title = getImgTitle(caption, credit);

  if (priority) {
    sortedSources.forEach((source, index) => {
      const sourceOpts = buildPriorityLoading({ source, contentId, imgData, envOverride });
      preload(sourceOpts.srcUrl, {
        ...preloadOpts,
        imageSizes: sourceOpts.sizesString,
        imageSrcSet: sourceOpts.srcSetUrl,
        // @ts-expect-error: types are incorrect
        media: generateMediaQueryForPreload(index, sortedSources),
      });
    });

    const fallbackOpts = buildPriorityLoading({
      source: fallbackImg,
      contentId,
      imgData,
      envOverride,
    });

    preload(fallbackOpts.srcUrl, {
      ...preloadOpts,
      imageSizes: fallbackOpts.sizesString,
      imageSrcSet: fallbackOpts.srcSetUrl,
      // @ts-expect-error: types are incorrect
      media: `(max-width:${widthBpsInEm[sortedSources[0].from] - 0.01}em)`,
    });
  }

  return (
    <picture>
      {sortedSources.map(source => (
        <HtzSource
          key={imgData.path}
          imgData={imgData}
          aspect={source.aspect}
          contentId={contentId}
          media={`(min-width:${widthBpsInEm[source.from]}em)`}
          widths={source.widths}
          type={source.type}
          sizes={source.sizes}
          envOverride={envOverride}
        />
      ))}
      <HtzImage
        {...attrs}
        alt={alt}
        aspect={fallbackImg.aspect}
        caption={caption}
        contentId={contentId}
        credit={credit}
        envOverride={envOverride}
        imgData={imgData}
        inlineStyle={inlineStyle}
        loading={loading}
        onError={onError}
        onLoad={onLoad}
        priority={priority}
        sizes={fallbackImg.sizes}
        styleExtend={[c.base, ...styleExtend]}
        title={title}
        type="image"
        widths={fallbackImg.widths}
      />
    </picture>
  );
}
